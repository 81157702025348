
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Select, Tabs, Input, Checkbox, Table, Statistic, Card, Tooltip } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import Utilities from '../../dependencies/custom/react-utilities';
import AppContainer from '../../components/container';



const StudentRegistrations = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');

    const subCourseAddMeta = 'subCourseAddMeta';
    const subCourseAdd = useAdd(subCourseAddMeta, 'table_name');
    // const subElectiveCourseAdd = useAdd(subCourseAddMeta, 'table_name');

    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [indexNumber, setIndexNumber] = useState('');
    const [studentInfo, setStudentInfo] = useState();
    const [credit, setCredit] = useState(0);
    const [expectedCredit, setExpectedCredit] = useState(0);
    const [currentAcadYr, setCurrecntAcadYr] = useState();
    const [currentSemester, setCurrentSemester] = useState();
    const [acadYears, setAcadYears] = useState([]);
    const [mcRuleExists, setMcRuleExists] = useState(false);
    const [mcRules, setMcRules] = useState({});
    const [mountedCoursesGeneralGrouped, setMountedCoursesGeneralGrouped] = useState({});
    const [mountedCoursesGrouped, setMountedCoursesGrouped] = useState({});
    const [generalTables, setGeneralTables] = useState({});
    const [coursesTables, setCoursesTables] = useState({});

    const [selectedGeneralCourses, setSelectedGeneralCourses] = useState({});
    const [selectedCourses, setSelectedCourses] = useState({});

    const [generalCoursesRules, setGeneralCoursesRules] = useState({});
    const [studentFetching, setStudentFetching] = useState(false);
    const [registerGeneralLoading, setRegisterGeneralLoading] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [selectedChildCourse, setSelectedChildCourse] = useState();
    const [registrationMode, setRegistrationMode] = useState('student_registration');

    const batchListTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 150,
                hideOnSinglePage: true,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: ['*'] });

    const rejectedListTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                hideOnSinglePage: true,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: ['*'] });

    function setupExtraFilters() {
        setTimeout(e => {
            valuesStore.setValue(extraFiltersAddMeta, [
                {
                    id: '', table_name: 'batch_registration_filters',
                    column_name: 'program', col_real_name: 'Program', type: 'sqlSelect',
                    options: '{"sql":"SELECT alias,short_name FROM programs","key":"alias","value":"short_name"}',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'batch_registration_filters',
                    column_name: 'current_level', col_real_name: 'Level', type: 'sqlSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'batch_registration_filters',
                    column_name: 'current_sem', col_real_name: 'Semester', type: 'sqlSelect',
                    options: '{"sql":"SELECT alias,semester FROM semester","key":"alias","value":"semester"}',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                },
                {
                    id: '', table_name: 'batch_registration_filters',
                    column_name: 'session', col_real_name: 'Session', type: 'sqlSelect',
                    options: '{"sql":"SELECT alias,session FROM sessions","key":"alias","value":"session"}',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                },
                {
                    id: '', table_name: 'batch_registration_filters',
                    column_name: 'current_acadyr', col_real_name: 'Academic Year', type: 'text',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                }
            ]);
            extraFiltersAdd.setTblName('batch_registration_filters');
        }, 1000);
    }



    const batchListColumns = ([
        {
            title: 'FirstName',
            dataIndex: 'fname',
            ...batchListTable.getColumnSearchProps('fname')
        },
        {
            title: 'LastName',
            dataIndex: 'lname',
            ...batchListTable.getColumnSearchProps('lname')
        },
        {
            title: 'MiddleName',
            dataIndex: 'mname',
            ...batchListTable.getColumnSearchProps('mname')
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...batchListTable.getColumnSearchProps('index_no')
        }
    ]);


    const rejectedListColumns = ([
        {
            title: 'FirstName',
            dataIndex: 'fname',
            ...batchListTable.getColumnSearchProps('fname')
        },
        {
            title: 'LastName',
            dataIndex: 'lname',
            ...batchListTable.getColumnSearchProps('lname')
        },
        {
            title: 'MiddleName',
            dataIndex: 'mname',
            ...batchListTable.getColumnSearchProps('mname')
        },
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...batchListTable.getColumnSearchProps('index_no')
        },
        {
            title: 'Debt/Error',
            dataIndex: 'errorMsg',
        }
    ]);

    async function addElectiveSubCourse() {
        // addSubCourse(subElectiveCourseAdd, electiveTable);
    }

    async function addSubCourse() {
        const record = subCourseAdd.record;
        if (!Object.keys(record).length) {
            return;
        }
        // const allCourses = { ...selectedGeneralCourses, ...selectedCourses };
        const selected = JSON.parse(JSON.stringify(selectedChildCourse));//deep copy does not copy function     
        let parent = selected?.details;
        let tableData = selected?.tableData;
        const group = selected?.courseGroup;
        const parentID = parent?.custom_id;
        const mcTable = selected?.mcTable;
        // if (!allCourses[parentID]) {
        //     Utilities.showNotification('Attention', 'Please check the course to select it first');
        //     return;
        // }
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_course_by_course_code`, null, { courseCode: record.subcourse });
        const theResult = res.result;
        if (!theResult.length) {
            return;
        }
        const result = theResult[0];
        const newData = tableData[group]['data']?.map(v => {
            if (v.custom_id == parentID) {
                return { ...v, child_course: { course_code: result.course_code, course_name: result.course_name, credit_hours: result.credit_hours } };
            }
            if (v.drop) {
                return {
                    ...v, drop: del.confirm(
                        `${Settings.backend}/delete`,
                        parent,
                        'Are you sure to delete this item',
                        { tableName: mcTable, where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0' icon={<i className='fas fa-trash ' />}></Button>
                        , undefined, undefined, undefined, () => {
                            getRegistrationDetails(false);
                        })
                };

            }
            return v;
        });

        if (mcTable == 'mounted_courses') {
            let obj = { ...coursesTables };
            obj[group]['data'] = newData;
            setCoursesTables(obj);
        } else if (mcTable == 'mounted_courses_general') {
            let obj = { ...generalTables };
            obj[group]['data'] = newData;
            setGeneralTables(obj);
        }
        subCourseAdd.setShowModal(false);
    }



    useMemo(() => {
        setupExtraFilters();

        batchListTable.setColumns(batchListColumns);
        rejectedListTable.setColumns(rejectedListColumns);
        batchListTable.setAllowSelection(true);
        console.log('looping')
        calcCredit();

    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, /*coreTable.selectedRows, electiveTable.selectedRows,*/ extraFiltersAdd.record]);

    function calcCredit() {
        // const crd1 = coreTable.selectedRows.reduce((prev, curr) => {
        //     return parseFloat(prev + curr.credit_hours);
        // }, 0);
        // const crd2 = electiveTable.selectedRows.reduce((prev, curr) => {
        //     return parseFloat(prev + curr.credit_hours);
        // }, 0);
        // setCredit(crd1 + crd2);
    }

    function callCourseChildren(details, subCourseAdd, mcTable, courseGroup, tableData) {
        setSelectedChildCourse({ courseGroup, details, tableData, mcTable });
        //This configurations allows the add hook to re-fetch fresh results on every click        
        subCourseAdd.resetCompletely();
        if (!details) {
            return;
        }
        valuesStore.setValue(subCourseAddMeta, [
            {
                id: '', table_name: 'subcourse_add',
                column_name: 'subcourse', col_real_name: 'Subcourse', type: 'sqlSelect',
                options: `{"sql":"SELECT course_code,course_name,custom_id FROM courses,${mcTable} WHERE ${mcTable}.mounted_for='${details.custom_id}' AND ${mcTable}.course_id = courses.course_code","key":"course_code","value":"course_code,course_name"}`,
                backend_visible: 1, front_visible: 0,
                editable: 1, validator: '', icon: '', rank: 1
            }]
        );
        subCourseAdd.setTblName('subcourse_add');
        subCourseAdd.setShowModal(true);
    }


    function theTabs() {
        return [
            {
                label: <><label className='d-none d-md-flex'>Student</label></>,
                key: 'student_registration',
                children: studentRegistration()
            },
            {
                label: <><label className='d-none d-md-flex'>Batch</label></>,
                key: 'batch_registration',
                children: batchRegistration()
            }
        ];
    }

    function reset() {
        // coreTable.setData([]);
        // electiveTable.setData([]);
        rejectedListTable.setData([]);
        batchListTable.setData([]);
        setExpectedCredit(0);
        setCredit(0);
    }

    async function getRegistrationDetails(isBatch) {
        setStudentFetching(true);
        let data = undefined;
        let url = '';
        if (isBatch) {
            url = 'get_batch_registration_details';
            data = { batchInfo: { ...extraFiltersAdd.record }, isBatch, currentAcadYr, currentSemester };
        } else {
            url = 'get_student_registration_details';
            data = { indexNumber, isBatch, currentAcadYr, currentSemester };
        }

        reset();

        const res = await utils.requestWithReauth('post', `${Settings.backend}/${url}`, null, data);

        if (res.status === 'Ok') {
            const studentDetails = res.studentDetails;
            if (!studentDetails.length) {
                utils.showNotification('Attention', 'No registration list found', 'topRight');
                // return;
            }

            if (!isBatch) {
                const debt = res?.debt;
                if (debt) {
                    if (typeof debt === 'number' && debt > 0) {
                        utils.showNotification('Attention', `The student owes an amount of GHC ${debt}`, 'topRight');
                    } else if (typeof debt === 'string') {
                        utils.showNotification('Attention', debt, 'topRight');
                    }
                }
            }
            if (isBatch) {
                const rejected = res?.rejected;
                if (rejected && rejected?.length) {
                    rejectedListTable.setData(rejected);
                }
            }

            const mountedCourses = res?.mountedCourses;
            const programName = (mountedCourses && mountedCourses.length) ? mountedCourses[0]?.name : '';
            const student = { program_name: programName, ...studentDetails[0] };

            setAcademicYears(student);
            setStudentInfo(student);
            setCurrentSemester(student?.current_sem);
            const rules = res.rules;
            const mountedCoursesGroups = Utilities.groupBy(mountedCourses, 'mount_as');
            const registeredCourses = res?.registeredCourses;
            generateCoursesTable(mountedCoursesGroups, registeredCourses, rules, setCoursesTables, setSelectedCourses, 'student_registration', 'mounted_courses');
            setMcRules(rules);
            setMountedCoursesGrouped(mountedCoursesGroups);


            const mountedCoursesGeneralGroups = res?.mountedCoursesGeneralGroups;
            const registeredCoursesGeneral = res?.registeredCoursesGeneral;
            const mountedCoursesGeneralRules = res?.mountedCoursesGeneralRules;
            generateCoursesTable(mountedCoursesGeneralGroups, registeredCoursesGeneral, mountedCoursesGeneralRules, setGeneralTables, setSelectedGeneralCourses, 'student_registration_general', 'mounted_courses_general');
            setGeneralCoursesRules(mountedCoursesGeneralRules);
            setMountedCoursesGeneralGrouped(mountedCoursesGeneralGroups);

            if (studentDetails.length >= 1 && isBatch) {
                generateBatchListTable(studentDetails);
            }
        } else {
            utils.showNotification(undefined, res.msg);
        }
        setStudentFetching(false);
    }

    function guide(rule, number) {
        switch (rule) {
            case 'exactly': {
                return `Select exactly ${number}`;
            }
            case 'atleast': {
                return `Select at least ${number}`;
            }
            case 'atmost': {
                return `Select at most ${number}`;
            }
            case 'range': {
                const n = number.split(',');
                return `Select at least ${n[0]} and at most ${n[1]}`;
            }
            case 'all': {
                return 'Select all courses';
            }
            default:
                return `No rule set`;
        }
    }


    function setAcademicYears(student) {
        const ay = genAcadYears(student);
        ay.length && setCurrecntAcadYr(ay[ay.length - 1]?.value);
        setAcadYears(ay);
    }

    function genAcadYears(student) {
        const currentAcadYr = parseInt(student?.current_acadyr?.split('/')[0]);
        const startAcadYr = parseInt(student?.start_acadyr?.split('/')[0]);
        let acadYears = [];
        for (let i = startAcadYr; i <= currentAcadYr; i++) {
            acadYears.push({
                value: `${i}/${(i + 1)}`,
                label: `${i}/${(i + 1)}`
            });
        }
        return acadYears;
    }

    function generateBatchListTable(list) {
        const mc = list.map(v => {
            return { fname: v.fname, lname: v.lname, mname: v.mname, id: v.id, index_no: v.index_no };
        });
        batchListTable.setData(mc);
    }


    function rowChecked(e, d, setter) {
        delete d['drop'];
        if (e.target.checked && d.is_parent && !d.child_course) {
            Utilities.showNotification('Attention', 'Please choose a subcourse first');
        }
        if (e.target.checked) {
            setter(r => ({ ...r, [d.key]: d }));
        } else {
            setter(r => {
                delete r[d.key];
                return r;
            });
        }
    }
    const generalCoursesCols = (courseGroup, mcTable, tableData, setSelectedCourses) => ([
        {
            title: <Checkbox disabled />,
            dataIndex: 'select',
            render(v, data) {
                //set checked to true if the drop key is found else false
                return <Checkbox onClick={e => rowChecked(e, data, setSelectedCourses)} defaultChecked={data['drop'] ? true : false} />
            }
        },
        {
            title: 'C.Code',
            dataIndex: 'course_code',
        },
        {
            title: 'C.Name',
            dataIndex: 'course_name',
            render(v, record) {
                if (record.child_course) {
                    return `${v} (${record?.child_course?.course_code} - ${record?.child_course?.course_name})`;
                } else {
                    return v;
                }
            }
        },
        {
            title: 'C.hours',
            dataIndex: 'credit_hours',
            render(v, record) {
                if (record.is_parent) {
                    return <>{v}<Tooltip title='Get the subcourses of this course'><Button onClick={e => callCourseChildren(record, subCourseAdd, mcTable, courseGroup, tableData)} className='btn-sm border-0'> <i className='fas fa-plus' /></Button></Tooltip></>
                } else {
                    return v;
                }
            }
        },
        {
            title: 'Drop',
            dataIndex: 'drop',
        }
    ]);

    function generateCoursesTable(list, rc, ruleExists, setTables, setSelectedCourses, regTable, mcTable) {
        let tablesData = {};
        for (let group in list) {
            let data = [];
            const g = list[group];

            for (let key of g || []) {
                let row = {
                    key: key['custom_id'],
                    custom_id: key['custom_id'],
                    course_code: key['course_code'],
                    course_name: key['course_name'],
                    credit_hours: key['credit_hours'],
                    group_name: group,
                    is_parent: key['is_parent'],
                    child_course: null,
                }

                for (let reg of rc || []) {
                    if (reg['mounted_course_id'] == key['custom_id'] || reg['parent_mounted_course_id'] == key['custom_id']) {
                        // if (registrationMode == 'student_registration') {
                        row = {
                            ...row,
                            drop: del.confirm(
                                `${Settings.backend}/delete`,
                                reg,
                                'Are you sure to delete this item',
                                { tableName: regTable, where: 'id', whereType: 'closed' },
                                <Button className='btn-danger border-0' icon={<i className='fas fa-trash ' />}></Button>
                                , undefined, undefined, undefined, () => {
                                    getRegistrationDetails(false);
                                })
                        }
                        setSelectedCourses(r => ({ ...r, [key['custom_id']]: { ...row } }));
                        // }
                    }
                }
                data.push(row);
            }
            tablesData[group] = {
                rules: ruleExists[group],
                guideText: ruleExists[group] ? ruleExists[group][0] : '',
                data: data,
                group,
                mcTable
            };
        }
        setTables(tablesData);
    }



    function generalCourseValidatate(rules, selectedCourses, allCourses) {
        const number = rules?.number;
        const rule = rules?.rule;
        const selectedRows = selectedCourses.length;
        switch (rule) {
            case 'exactly': {
                return number == selectedRows;
            }
            case 'atleast': {
                return selectedRows >= number;
            }
            case 'atmost': {
                return selectedRows <= number;
            }
            case 'range': {
                const n = number.split(',');
                return selectedRows >= n[0] && selectedRows <= n[1];
            }
            case 'all': {
                return allCourses?.length == selectedRows;
            }
            default:
                return true;
        }
    }

    function coursesValidator(courses, generalCoursesRules, coursesFromDBRequestGrouped) {
        for (let group in generalCoursesRules) {
            const selectedCourses = courses[group];
            const r = generalCoursesRules[group][0];
            const allCoursesInGroup = coursesFromDBRequestGrouped[group];
            if (!selectedCourses) {
                const g = guide(r?.rule, r?.number);
                return { status: false, msg: `No selection for ${group}. ${g}` };
            }
            const bool = generalCourseValidatate(r, selectedCourses, allCoursesInGroup);
            if (!bool) {
                const g = guide(r?.rule, r?.number);
                return { status: false, msg: `${g} from ${group}` };
            }
        }
        return { status: true, msg: '' };
    }

    async function registerCoursesGeneral(list) {
        const regCourses = Object.values(selectedGeneralCourses);
        const groupedCourses = Utilities.groupBy(regCourses, 'group_name');
        const validate = coursesValidator(groupedCourses, generalCoursesRules, mountedCoursesGeneralGrouped);

        if (!validate.status) {
            utils.showNotification(undefined, validate?.msg);
            return;
        }
        for (let i = 0; i < regCourses.length; i++) {
            if (regCourses[i].is_parent) {
                const childCourse = regCourses[i].child_course;
                if (!childCourse) {
                    utils.showNotification(undefined, `Please choose a subcourse for ${regCourses[i].course_code}-${regCourses[i].course_name}`);
                    return;
                }
            }
        }
        if (!regCourses.length) {
            utils.showNotification(undefined, `No course registration data found`);
            return;
        }
        const data = { list, regCourses, };
        const res = await utils.requestWithReauth('post', `${Settings.backend}/register_courses_general`, null, data);
        if (res.status === 'Ok') {
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            setGeneralTables({});
            // setCredit(0);
            // setExpectedCredit(0);           
        } else {
            utils.showNotification(undefined, res.msg);
        }

    }


    async function registerCourses(list) {
        const regCourses = Object.values(selectedCourses);
        const groupedCourses = Utilities.groupBy(regCourses, 'group_name');
        const validate = coursesValidator(groupedCourses, mcRules, mountedCoursesGrouped);

        if (!validate.status) {
            utils.showNotification(undefined, validate?.msg);
            return;
        }

        for (let i = 0; i < regCourses.length; i++) {
            if (regCourses[i].is_parent) {
                const childCourse = regCourses[i].child_course;
                if (!childCourse) {
                    utils.showNotification(undefined, `Please choose a subcourse for ${regCourses[i].course_code}-${regCourses[i].course_name}`);
                    return;
                }
            }
        }
        if (!regCourses.length) {
            utils.showNotification(undefined, `No course registration data found`);
            return;
        }

        const data = { list, regCourses, };
        const res = await utils.requestWithReauth('post', `${Settings.backend}/register_courses`, null, data);
        if (res.status === 'Ok') {
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            setCoursesTables({});
            // setCredit(0);
            // setExpectedCredit(0);           
        } else {
            utils.showNotification(undefined, res.msg);
        }


    }

    async function studentRegisterCourses() {
        setRegisterLoading(true);
        await registerCourses([studentInfo]);
        setRegisterLoading(false);
    }

    async function studentRegisterCoursesGeneral() {
        setRegisterGeneralLoading(true);
        await registerCoursesGeneral([studentInfo]);
        setRegisterGeneralLoading(false);
    }

    async function batchRegisterCourses() {
        await registerCourses(batchListTable.selectedRows);
    }

    function handleAcadYrChange(v) {
        setCurrecntAcadYr(v);
    }
    function handleSemesterChange(v) {
        setCurrentSemester(v);
    }

    function genSemesters() {
        return valuesStore.getValue('semester')?.map(v => ({ value: v.alias, label: v.semester }));
    }

    function studentRegistration() {
        const jsx =
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className='d-flex'>
                            <Input type='text' placeholder='Enter Index Number' onPressEnter={e => getRegistrationDetails(false)} value={indexNumber} onChange={e => setIndexNumber(e.target.value)} className='w-25 me-3' size="large" />
                            <Select
                                value={currentAcadYr}
                                size='large'
                                className='me-3'
                                placeholder="Select academic year"
                                onChange={handleAcadYrChange}
                                options={acadYears}
                            />
                            <Select
                                value={currentSemester}
                                size='large'
                                className='me-3 w-50'
                                placeholder="Select semester"
                                onChange={handleSemesterChange}
                                options={genSemesters()}
                            />
                            <Button size='large' type='primary' loading={studentFetching} onClick={e => getRegistrationDetails(false)} > <i className='fas fa-search me-2' /> Fetch Student</Button>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        {studentInfo && <div className="">
                            <div className="row row-cols-3 p-2 stylish-colorx rounded fw-bold blue lighten-5 text-muted">
                                <div className="col">
                                    First Name: {studentInfo?.fname}<br />
                                    Last Name: {studentInfo?.lname}<br />
                                    Middle Name: {studentInfo?.mname}<br />
                                </div>
                                <div className="col">
                                    Index Number: {studentInfo?.index_no}<br />
                                    Program: {studentInfo?.program_name}<br />
                                    Level: {studentInfo?.current_level}
                                </div>
                                <div className="col">
                                    Session: {studentInfo?.session}<br />
                                    Current Semester :{studentInfo?.current_sem}
                                </div>
                            </div>
                        </div>}
                    </div>

                    <div className='col-md-8'>
                        <div className='bg-white mt-3 p-3'>
                            {Object.values(coursesTables)?.map(v => {
                                return <div key={v?.group}>
                                    <label className='fw-bold'>{v?.group} ({guide(v?.guideText?.rule, v?.guideText?.number)})</label>
                                    <div className='table-responsive h-scrolling-wrapper'>
                                        <Table columns={generalCoursesCols(v?.group, v?.mcTable, coursesTables, setSelectedCourses)} dataSource={v?.data} pagination={false} />
                                    </div>
                                </div>
                            })}
                            {Object.values(coursesTables).length >= 1 && <Button loading={registerLoading} onClick={e => studentRegisterCourses()} className='mt-2' type='primary'><i className='fas fa-paper-plane me-2' /> Register</Button>}
                        </div>

                        <div className='bg-white mt-3 p-3'>
                            {Object.values(generalTables)?.map(v => {
                                return <div key={v?.group}>
                                    <label className='fw-bold'>{v?.group} ({guide(v?.guideText?.rule, v?.guideText?.number)})</label>
                                    <div className='table-responsive h-scrolling-wrapper'>
                                        <Table columns={generalCoursesCols(v?.group, v?.mcTable, generalTables, setSelectedGeneralCourses)} dataSource={v?.data} pagination={false} />
                                    </div>
                                </div>
                            })}
                            {Object.values(generalTables).length >= 1 && <Button loading={registerGeneralLoading} onClick={e => studentRegisterCoursesGeneral()} className='mt-2' type='primary'> <i className='fas fa-paper-plane me-2' /> Register</Button>}
                        </div>
                    </div>

                    <div className='mt-4 col-md-4'>
                        <Card bordered={false} /*onClick={e => test()}*/>
                            {mcRuleExists ? '' : <Statistic
                                title="Expected Credit Hours"
                                value={expectedCredit}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                            />}
                            <Statistic
                                title="Selected Credit Hours"
                                value={credit}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                            />
                        </Card>
                    </div>
                </div>
            </div>;
        return jsx;
    }

    function batchRegistration() {
        const jsx =
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div>
                            {extraFiltersAdd.form}
                        </div>
                        <div className=''>
                            <Button type='primary' onClick={e => getRegistrationDetails(true)} > <i className='fas fa-search me-2' /> Fetch Batch</Button>

                        </div>
                    </div>
                    {studentInfo && <Space className="p-2 stylish-colorx rounded fw-bold d-flex fw-bold blue lighten-5 text-muted">
                        <label>Program: {studentInfo.program_name} | </label>
                        <label>Level: {studentInfo.current_level} | </label>
                        <label>Academic Year: {studentInfo.current_acadyr} | </label>
                        <label>Session: {studentInfo.session} | </label>
                        <label>Current Semester :{studentInfo.current_sem}</label>
                    </Space>}
                </div>


                <div className='row mt-3'>
                    <div className='col-md-7 border bg-white'>
                        {batchListTable.table}
                    </div>
                    <div className='col-md-5 border bg-white'>
                        <div className='mt-4x col-md-6x borderx p-2x bg-whitex border-roundedx'>
                            {Object.values(coursesTables)?.map(v => {
                                return <div key={`${v?.group}_batch`}>
                                    <label className='fw-bold'>{v?.group} ({guide(v?.guideText?.rule, v?.guideText?.number)})</label>
                                    <div className='table-responsive h-scrolling-wrapper'>
                                        <Table columns={generalCoursesCols(v?.group, v?.mcTable, coursesTables, setSelectedCourses)} dataSource={v?.data} pagination={false} />
                                    </div>
                                </div>
                            })}

                            {Object.values(coursesTables).length >= 1 && <Button loading={registerLoading} onClick={e => batchRegisterCourses(true)} className='mt-2' type='primary'><i className='fas fa-paper-plane me-2' /> Register</Button>}
                            {/* <Button onClick={e => batchRegisterCourses(true)} className='mt-2' type='primary' icon={<i className='fas fa-paper-plane me-2' />}>Register</Button> */}
                        </div>
                        <div className='mt-2'>
                            <Card bordered={false}>
                                {mcRuleExists ? '' : <Statistic
                                    title="Expected Credit Hours"
                                    value={expectedCredit}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                />}
                                <Statistic
                                    title="Selected Credit Hours"
                                    value={credit}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                />
                                {/* <label>Student is about to be registered</label> */}
                            </Card>
                        </div>
                    </div>
                    <div className='border'>
                        <label className='h6 mb-2 text-danger'>Rejected List</label>
                        {rejectedListTable.table}
                    </div>
                </div>
            </div>;
        return jsx;
    }

    return (
        <>
            <AppContainer
                extra={
                    <>
                        
                    </>
                }
                title={'Student Registration'}
                children={
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            <Tabs
                                onChange={e => { setRegistrationMode(e) }}
                                items={theTabs()}
                            />
                        </div>
                    </div>
                }                                
            /> 

            {edit.editModal('Edit Item', null)}
            {add.addModal('Add New Item', null)}
            {subCourseAdd.addModal('Add Subcourse', addSubCourse)}
            {/* {subElectiveCourseAdd.addModal('Add Subcourse', addElectiveSubCourse)} */}

        </>
    );
}

export default memo(StudentRegistrations);